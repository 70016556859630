<template>
    <div class="contact-form">
        <div class="form-container">
            <div class="form-body">
            
                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>手机号</span>
                            <em>*</em>
                        </div>
                    </div>
                    <div class="item">
                        <n-input placeholder="请输入手机号" v-model:value="formData.phone" />
                    </div>
                </div>

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>电话</span>
                            <em>*</em>
                        </div>
                    </div>
                    <div class="item">
                        <n-input placeholder="请输入电话" v-model:value="formData.tel" />
                    </div>
                </div>

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>电子邮箱</span>
                            <em>*</em>
                        </div>
                    </div>
                    <div class="item">
                        <n-input placeholder="请输入电子邮箱" v-model:value="formData.email" />
                    </div>
                </div>

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>域名</span>
                            <em>*</em>
                        </div>
                    </div>
                    <div class="item">
                        <n-input placeholder="请输入网站域名" v-model:value="formData.url" />
                    </div>
                </div>

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>联系地址</span>
                            <em>*</em>
                        </div>
                    </div>
                    <div class="item">
                        <n-input placeholder="请输入联系地址" v-model:value="formData.location" />
                    </div>
                </div>

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>Facebook</span>
                        </div>
                    </div>
                    <div class="item">
                        <n-input placeholder="请输入主页地址" v-model:value="formData.facebook" />
                    </div>
                </div>

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>YouTube</span>
                        </div>
                    </div>
                    <div class="item">
                        <n-input placeholder="请输入主页地址" v-model:value="formData.youtube" />
                    </div>
                </div>

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>Twitter</span>
                        </div>
                    </div>
                    <div class="item">
                        <n-input placeholder="请输入主页地址" v-model:value="formData.twitter" />
                    </div>
                </div>

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>Instagram</span>
                        </div>
                    </div>
                    <div class="item">
                        <n-input placeholder="请输入主页地址" v-model:value="formData.instagram" />
                    </div>
                </div>

            </div>
        </div>

        <div class="bottom-tools-box">
            <n-button class="tool-btn" size="large" @click="location.reload()">重置</n-button>
            <n-button 
                class="tool-btn" 
                size="large" 
                type="primary" 
                :loading="btnLoading"
                :disabled="formData.phone === '' || formData.tel === '' || formData.email === '' || formData.url === '' || formData.location === ''"
                @click="$emit('submit', formData)"
            >确定</n-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactForm',
    props: {
        data:{
            type: Object,
            default: {}
        },
        btnLoading: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            formData: {
                phone: '',
                tel: '',
                email: '',
                url: '',
                location: '',
                facebook: '',
                youtube: '',
                twitter: '',
                instagram: ''
            }
        }
    },
    watch: {
        'data':{
            handler(){
                if(this.data.phone) this.formData = this.data;
            },
            deep: true
        }
    },
    mounted(){
        if(this.data.phone) this.formData = this.data;
    },
}
</script>

<style lang="scss" scoped>
@import 'form-style';
.contact-form{
    margin: 15px;
    .form-container{
        margin: 0;
    }
}
</style>